import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { Container } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { IInfoBannerProps } from './model';
import './InfoBanner.scss';

const InfoBanner: FC<IInfoBannerProps> = ({
  logo,
  background,
  image,
  color,
  disclaimer = '',
  description = '',
  cta = [],
  bannerTheme,
  topImage,
}) => {
  const bannerLogo = logo?.[0]?.properties;
  const bannerBackground = background?.[0]?.properties;
  const bannerImage = image?.[0]?.properties;
  const secondImage = topImage?.[0]?.properties;
  const DisclaimerElement = () =>
    disclaimer ? (
      <DangerouslySetInnerHtml className="info-banner__disclaimer" html={disclaimer} />
    ) : null;

  return (
    <div
      className={classNames('info-banner', {
        [`info-banner--${color?.label}`]: color?.label,
        [`info-banner--${bannerTheme?.[0]}`]: bannerTheme?.[0],
        'info-banner--dark-theme': color?.label !== 'quantum-silver',
        'info-banner--two-images': bannerImage && secondImage,
      })}
    >
      <Container fluid>
        <div className="info-banner__grid">
          {secondImage?.image ? (
            <div className="info-banner__media">
              <div className="info-banner__image">
                <GatsbyImage
                  image={secondImage.image}
                  alt={secondImage.imageAlt}
                  objectFit="contain"
                  className="info-banner__image-img"
                />
              </div>
            </div>
          ) : null}
          <div className="info-banner__text">
            {bannerLogo?.image ? (
              <div className="info-banner__logo">
                <GatsbyImage image={bannerLogo.image} alt={bannerLogo?.imageAlt} />
              </div>
            ) : null}
            {description ? (
              <DangerouslySetInnerHtml className="info-banner__description" html={description} />
            ) : null}
            {cta?.length > 0 && (
              <div className="info-banner__buttons">
                {cta.map(({ properties: { link, ariaLabel = '' } }, idx) => {
                  const linkItem = link?.[0];

                  return (
                    <Button
                      to={linkItem?.url}
                      key={linkItem?.name}
                      ariaLabel={ariaLabel}
                      variant={idx === 0 ? 'primary-link' : 'secondary-link'}
                      iconSuffix="chevron-right"
                      target={linkItem?.target}
                    >
                      {linkItem?.name}
                    </Button>
                  );
                })}
              </div>
            )}
            <DisclaimerElement />
          </div>
          <div className="info-banner__media">
            {bannerBackground?.image ? (
              <div className="info-banner__background">
                <GatsbyImage
                  image={bannerBackground.image}
                  alt={bannerBackground?.imageAlt}
                  style={{ position: 'absolute' }}
                  className="info-banner__background-img"
                />
              </div>
            ) : null}

            {bannerImage?.image ? (
              <div className="info-banner__image">
                <GatsbyImage
                  image={bannerImage.image}
                  alt={bannerImage.imageAlt}
                  objectFit="contain"
                  className="info-banner__image-img"
                />
              </div>
            ) : null}
          </div>
        </div>
        <DisclaimerElement />
      </Container>
    </div>
  );
};

export default InfoBanner;

export const query = graphql`
  fragment FragmentInfoBanner on TInfoBanner {
    properties {
      description
      disclaimer
      color {
        label
      }
      logo {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 150) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      background {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 450, srcSetBreakpoints: [360, 380, 420]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      image {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 780, srcSetBreakpoints: [360, 380, 420, 560], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      topImage {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 780, srcSetBreakpoints: [360, 380, 420, 560], quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      cta {
        properties {
          ariaLabel
          link {
            name
            target
            url
          }
        }
      }
      bannerTheme
    }
    structure
  }
`;
